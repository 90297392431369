import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LazyImage } from 'components/lazy-image';
import { withBrowserImage } from 'components/browser-image';
import './image-desktop.less';
const LazyImageWithBrowserImage = withBrowserImage(LazyImage);

const ImageDesktop = (props) => {
  const { imgList, handleImagesClick } = props;
  return (
    <div className="image_desktop">
      {imgList.map((item, i) => (
        <span tabIndex={0} role="button"
          key={`${item.original_url}${i}`}
          className={classNames({
            desc_pic: imgList.length > 1,
            desc_pic_one: imgList.length === 1,
          })}
          onClick={() => handleImagesClick(i)}
          onKeyPress={() => handleImagesClick(i)}
        >
          <LazyImageWithBrowserImage className="pic_img" src={removeProtocol(item.thumb_url)} />
        </span>
      ))}
    </div>
  );
};

/**
 * 过滤掉 URL 中掉 http:// 协议头，改成 //，避免后台返回绝对路径
 * @param url
 */
const removeProtocol = url => url.replace(/^https?:/i, '');

ImageDesktop.defaultProps = {
  imgList: [],
  original_url: '',
  handleImagesClick() {
  },
};

ImageDesktop.propTypes = {
  imgList: PropTypes.array,
  handleImagesClick: PropTypes.func,
};



export { ImageDesktop };
