import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TcIconChatLine } from 'components/IconSystem';

export const BtnReply = (props) => (
  <div
    className={classNames('btn btn-reply', { disabled: props.is_disabled })}
    tabIndex={0}
    role="button"
    onClick={props.onClick}
    onKeyPress={props.onClick}
  >
    {/* <TcIconChatLine /> */}
    {props.is_disabled ? (
      <span className="btn-text">已关闭回复</span>
    ) : (
      <span className="btn-text">{props.is_show ? '取消回复' : '回复'}</span>
    )}
  </div>
);

BtnReply.propTypes = {
  is_disabled: PropTypes.bool,
  is_show: PropTypes.bool,
  onClick: PropTypes.func,
};
