import React from 'react';
import { Avatar } from 'components/user';
import './style.less';
export const UserApprec = (props) => (
    <div className="user_apprec">
        {
            props.tip_list.map((item, index) => (
                <Avatar className="user_img" src={item.avatar} key={index} />
            ))
        }
        <span className="apprec_times">
            {props.tip_list.length}人赞赏 共{(props.tip_amount_total / 100).toFixed(2)}元
        </span>
    </div>
);

UserApprec.defaultProps = {
    tip_list: [],
    tip_amount_total: 0,
};
