/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageAlbum } from '@tencent/coral-design';
import { Avatar } from 'components/user';
import { BtnsStyle, BtnLike, BtnMoreWithDropdown, BtnReply } from 'components/btn-icon';
import { ImageDesktop } from 'components/comment-image/image-desktop';
import { Menu, ConfirmDelete } from 'components/comment-item';
import { href_profile } from 'components/href-helper';
import { postsTime } from 'components/util';
import { DescPara } from './desc-para.desktop';

export const CommentItemPc = (props) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showImgAlbum, setShowImgAlbum] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef(null);

  const handleConfirmDelete = () => {
    const replyId = props.f_reply_id || props.id;

    setConfirmDelete(false);
    setShowMenu(false);

    props.onClickDelete(replyId);
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
    dropdownRef.current.close();
  };

  const onClickMenu = ({ opt, val }) => {
    const replyId = props.f_reply_id || props.id;

    if (opt === 'delete') {
      setConfirmDelete(true);
      return;
    }
    setShowMenu(false);
    props.onClickMenu && props.onClickMenu({ opt, val }, replyId);
  };
  const isOwner = Boolean(props.user_id === props.userData.user_id);

  const onClickLiked = () => {
    const { like_count, like_flag } = props.likedInfo;
    const likedObj = {
      like_count: like_flag ? like_count - 1 : like_count + 1,
      like_flag: !like_flag,
    };

    props.onRepliesLiked(likedObj);
  };

  const onBtnMoreClick = () => {
    if (props.onCheckLogin()) {
      setShowMenu(true);
    }
  };

  // 返回显示标签
  const getTag = (isAdmin, isAuthorId, isMine) => {
    if (isAdmin) {
      return <span className="tag__admin">官方</span>;
    }
    if (isAuthorId) {
      return <span className="tag__author">作者</span>;
    }
    if (isMine) {
      return <span className={'tag__mine'}>我的</span>;
    }
    return null;
  };

  return (
    <div className="comment_item_pc">
      <a className="user_avatar" target="_blank" href={href_profile(props.user_id)} rel="noreferrer">
        <Avatar src={props.avatar_url} />
      </a>
      <div className="item_content">
        <div className="user_info">
          <h5 className="user_name">
            <a
              className={classNames('link', { admin: props.is_admin })}
              target="_blank"
              href={href_profile(props.user_id)}
              rel="noreferrer"
            >
              {props.nick_name}
            </a>
            {getTag(
              props.is_admin,
              props.postUserId === props.user_id,
              props.user_id === props.userData.user_id,
            )}
            {props.parent && props.parent.nick_name && (
              <>
                <i className="jt"></i>
                <a className={classNames('link', { admin: props.parent.is_admin })} href=" ">
                  {props.parent.nick_name}
                </a>
                {getTag(
                  props.parent.is_admin,
                  props.postUserId === props.parent.user_id,
                  props.parent.user_id === props.userData.user_id
                )}
              </>
            )}
          </h5>
          {/* {props.parent */}
          {/*   && getTag( */}
          {/*     props.parent.is_admin, */}
          {/*     props.postUserId === props.user_id, */}
          {/*     props.parent.user_id === props.userData.user_id */}
          {/*   )} */}
          {/* {props.is_admin */}
          {/*   && !props.parent */}
          {/*   && getTag( */}
          {/*     props.is_admin, */}
          {/*     props.postUserId === props.user_id, */}
          {/*     props.user_id === props.userData.user_id */}
          {/*   )} */}
          <div className="public_time">{postsTime(props.created_at_timestamp)}</div>
        </div>
        <DescPara desc={props.f_reply_text} renderLink={props.renderLink === 'on' || props.is_admin} />
        {Boolean(props.images.length) && (
          <ImageDesktop imgList={props.images} handleImagesClick={(i) => setShowImgAlbum(i)} />
        )}
        <BtnsStyle>
          <BtnMoreWithDropdown onClick={() => onBtnMoreClick()} ref={dropdownRef}>
            {showMenu && (
              <>
                <div style={{ display: confirmDelete ? 'block' : 'none' }}>
                  <ConfirmDelete onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} />
                </div>

                <div style={{ display: !confirmDelete ? 'block' : 'none' }}>
                  <Menu
                    is_tag={true}
                    is_reply={true}
                    is_spam={props.is_spam}
                    is_admin={props.userData.is_admin}
                    is_owner={isOwner}
                    is_admin_post={props.is_admin}
                    onClick={onClickMenu}
                    hideList={props.hideActionList}
                    tagArrList={props.tagArrList}
                    changePostsList={props.changePostsList}
                  />
                </div>
              </>
            )}
          </BtnMoreWithDropdown>
          <BtnLike
            like_count={props.likedInfo.like_count}
            isLiked={props.likedInfo.like_flag}
            onClick={onClickLiked}
          />
          <BtnReply is_disabled={props.is_locked} onClick={props.onReply} />
        </BtnsStyle>
      </div>
      {showImgAlbum !== null && (
        <ImageAlbum
          imageList={props.images.map((img) => img.original_url)}
          previewList={props.images.map((img) => img.thumb_url)}
          showPreview={true}
          showIndex={showImgAlbum}
          onClose={() => setShowImgAlbum(null)}
        />
      )}
    </div>
  );
};
CommentItemPc.propTypes = {
  f_reply_id: PropTypes.string,
  id: PropTypes.string,
  postUserId: PropTypes.number,
  user_id: PropTypes.number,
  avatar_url: PropTypes.string,
  nick_name: PropTypes.string,
  group_name: PropTypes.string,
  postname: PropTypes.string,
  created_at_timestamp: PropTypes.number,
  f_reply_text: PropTypes.string,
  is_locked: PropTypes.bool,
  is_spam: PropTypes.bool,
  is_admin: PropTypes.bool,
  images: PropTypes.array,
  hideActionList: PropTypes.array,
  tagArrList: PropTypes.array,
  parent: PropTypes.object,
  userData: PropTypes.object,
  likedInfo: PropTypes.object,
  onReply: PropTypes.func,
  onRepliesLiked: PropTypes.func,
  onClickMenu: PropTypes.func,
  onClickDelete: PropTypes.func,
  onCheckLogin: PropTypes.func,
  changePostsList: PropTypes.func,
  renderLink: PropTypes.string,
};
CommentItemPc.defaultProps = {
  images: [],
  likedInfo: {
    like_count: 0,
    like_flag: false,
  },
  f_reply_text: '',
  user_id: 0,
  userData: {},
  hideActionList: [],
  onClickDelete: () => console.log('onClickDelete 没有'),
  onClickMenu: () => console.log('onClickMenu 没有'),
  onRepliesLiked: () => console.log('onRepliesLiked 没有'),
  onCheckLogin: () => false,
};
