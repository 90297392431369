/* eslint-disable camelcase, react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { isPlainObject } from 'lodash-es';
import Route from 'components/util/route';
import { TcIconMore } from 'components/IconSystem';
import Tag from 'components/tag';
import { ImageMobile } from 'components/comment-image';
import { Avatar } from 'components/user';
import { BtnsStyle, BtnLike, BtnComment } from 'components/btn-icon';
import { ariaRole } from 'components/util/aria';
import IWantCard from 'components/i-want/i-want-card-mobile';
import { href_profile, href_post } from 'components/href-helper';
import { POST_TYPE } from 'components/constants/app';
import { TAG_COLOR } from 'components/tag-show/constants';
import Topic from 'components/topic/topic-container';
import { DescPara } from './desc-para.mobile';
import { CommentItem } from './comment.mobile';
import { getChangeLogContent, getProductId } from 'components/util';
import { PostPropTypes } from './prop-types.js';

import goodIssue from 'components/assets-image/common-icon/good_issue.png';
import './post-item.mobile.less';
import { EmbedChangeLog } from '../change-log-embed';

const PostItem = (props) => {
  let commentList = [];
  if (isPlainObject(props.replies)) {
    commentList = Object.keys(props.replies).map(id => props.replies[id]);
  }
  const productId = getProductId();

  /** 点赞避免冒泡 */
  const onClickLike = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onLiked();
  };

  /** 点击更多避免冒泡 */
  const onClickMore = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClickMore();
  };

  /** 点击用户头像或昵称，跳转去用户页 */
  const onClickUser = (event) => {
    event.preventDefault();
    event.stopPropagation();
    Route.push(href_profile(props.user_id));
  };
    // 获取内容读屏
  const getContentAria = () => {
    const { is_top, is_hidden, is_spam, is_notice, is_good, content, time } = props;
    const top = is_top ? '置顶' : '';
    const hidden = is_hidden ? '隐藏' : '';
    const spam = is_spam ? '黑名单' : '';
    const notice = is_notice ? '公告' : '';
    const good = is_good ? '好问题' : '';
    return `${top} ${hidden} ${spam} ${notice} ${good} ${content} ${time}`;
  };
  if (props.type === POST_TYPE.I_WANT) {
    return (
      <IWantCard
        {...props}
        onClickUser={onClickUser}
        onClickLike={onClickLike}
        onClickMore={onClickMore}
        renderLink={props.renderLink}
      />
    );
  }
  function styleyClass(item) {
    const selectColor = TAG_COLOR.find(i => i.color === item.setting.color) || {};
    return {
      backgroundColor: selectColor.bd || '#eee',
      color: '#fff',
      border: 'none',
    };
  }

  const renderContent = () => {
    if (props.type === POST_TYPE.CHANGE_LOG) {
      const changeLog = getChangeLogContent(props.content);
      changeLog.isListView = true;
      changeLog.id = props.id;
      changeLog.date = props.created_at_timestamp;
      return <EmbedChangeLog
        changeLog={changeLog}
        isDetail={true}
        showLink={false}
        style={{ maxHeight: '5.5rem', overflow: 'hidden' }}
        productId={props.productId}
      />;
    }
    return <DescPara desc={props.content} renderLink={props.renderLink === 'on' || props.is_admin} />;
  };

  const handleReplyBtnClick = () => {
    Route.push(href_post(props.id));
  };

  // 这里...实在是一言难尽，不同接口用了reply_num 和 reply_count 。甚至同一个接口用了 reply_num 和 reply_count...
  const repliyCount = props.reply_num || props.reply_count || 0;
  return (
    <Tag.div className="post_item_mobile post_list_item" >
      <Tag.span tag="span" onClick={onClickUser}>
        <Avatar src={props.avatar_url} className="item_user" />
      </Tag.span>
      <Tag.div className="item_content">
        <Tag.div className="title">
          <Tag.h3
            className={classNames('title_name', { 'title_name--admin': props.is_admin })}
            onClick={onClickUser}
            {...ariaRole('link')}
            aria-label={`${props.is_admin ? '管理员' : ''}${props.nick_name} 前往${props.nick_name} 个人中心`}>
            {props.nick_name}{props.group_name && ` · ${props.group_name}`}
          </Tag.h3>
          <Tag.div className="title_label" aria-hidden={true}>
            {props.tags && props.tags.map((item, i) => (
              <Tag.span className="label_font label_tag" key={`${i}`}
                style={styleyClass(item)}>{item.name}</Tag.span>
            ))}
            {props.is_spam && <Tag.span className="label_font">黑名单</Tag.span>}
            {props.is_notice && <Tag.span className="label_font">公告</Tag.span>}
            {props.is_good && <Tag.span className="label_font label_good"><Tag.img className="label_good__icon" src={goodIssue} alt="好问题" /></Tag.span>}
            {props.is_hidden && <Tag.span className="label_font">隐藏</Tag.span>}
            {props.is_top && <Tag.span className="label_font">置顶</Tag.span>}
          </Tag.div>
        </Tag.div>
        {props.showTopic && <Tag.div className="topic_title">
          <Topic productId={productId} postId={props.id} isProduct />
        </Tag.div>}
        <Tag.a href={href_post(props.id)} aria-label={getContentAria()} style={{ display: 'block', position: 'relative' }}>
          {renderContent()}
        </Tag.a>
        {
          (Boolean(props.images.length)) && (
            <Tag.div className="photowall">
              <ImageMobile imgList={props.images} />
            </Tag.div>
          )
        }
        <Tag.div className="comment_fun">
          <Tag.div className="publish" aria-hidden={true}>{props.time}</Tag.div>
          <Tag.div className="operate_btn">
            <BtnsStyle isMobile={true}>
              <BtnComment
                onClick={handleReplyBtnClick}
                is_disabled={false}

                replyNum={repliyCount}
              />
              <BtnLike
                like_count={props.like_count}
                isLiked={props.is_liked}
                default_count={0}
                onClick={onClickLike}
              />
              <Tag.button
                className="btn btn-item"
                onClick={onClickMore}
                {...ariaRole('button')}
                aria-label="更多操作"
                needAuth
              >
                <TcIconMore />
              </Tag.button>
            </BtnsStyle>
          </Tag.div>
        </Tag.div>
        {
          Boolean(commentList.length) && (
            <Tag.div className="commodule">
              <Tag.div className="comment_list">
                {
                  commentList.map((cont, i) => (
                    <CommentItem
                      key={i}
                      {...cont}
                      parent={props.replies[cont.parent_reply_id]}
                      renderLink={props.renderLink}
                    />
                  ))
                }
              </Tag.div>
              {
                // reply_num 条 这里因为后台数据不能实时更新原因暂时不具体显示还有多少条评论
                (repliyCount > 3) && (
                  <Tag.a className="comment_total" href={href_post(props.id)}>查看全部评论 &gt;</Tag.a>
                )
              }
            </Tag.div>
          )
        }
      </Tag.div>
    </Tag.div>
  );
};

PostItem.defaultProps = {
  avatar_url: '',
  nick_name: '-',
  content: '',
  is_top: false,
  is_good: false,
  is_liked: false,
  is_notice: false,
  images: [],
  onLiked: () => console.log('未绑定 onLiked'),
  onClickMore: () => console.log('未绑定 onClickMore'),
};

PostItem.propTypes = PostPropTypes;

export { PostItem };
