import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { BtnMoreWithDropdown } from 'components/btn-icon';
import { ConfirmDelete } from './confirm-delete';
import { ConfirmSpam } from './confirm-spam';
import { Menu } from './menu';

export class CommentOperator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm_delete: false,
      confirm_spam: false,
      showMenu: false,
    };

    this.onClickMenu = this.onClickMenu.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.confirmSpam = this.confirmSpam.bind(this);

    this.dropdownRef = React.createRef();
  }

  onClickMenu({ opt, val }) {
    const { state, props } = this;

    if (opt === 'spam' && val === 'on') {
      this.setState({ confirm_spam: true });
      return;
    }

    if (opt === 'delete') {
      this.setState({ confirm_delete: true });
      return;
    }

    this.dropdownRef.current.close();
    this.setState({ showMenu: false });
    props.onClickMenu({ opt, val });
  }

  confirmDelete() {
    const { props } = this;
    this.hideDropdown();
    this.setState({ showMenu: false, confirm_delete: false });
    props.onClickDelete();
  }

  hideDropdown() {
    this.setState({ confirm_delete: false });
    this.dropdownRef.current.close();
  }

  confirmSpam() {
    const { props } = this;
    props.onClickMenu({ opt: 'spam', val: 'on' });
    this.setState({ confirm_spam: false });
  }

  onBtnMoreClick() {
    if (this.props.onCheckLogin()) {
      this.setState({ showMenu: true });
    } else {
      this.setState({ showMenu: false });
    }
  }
  render() {
    const { state, props } = this;

    const isShowMenu = !state.confirm_spam && !state.confirm_delete;
    return (
      <BtnMoreWithDropdown
        ref={this.dropdownRef}
        hasParentEle={props.hasParentEle}
        onClick={() => this.onBtnMoreClick()}
      >
        <div style={{ display: state.confirm_delete ? 'block' : 'none' }}>
          <ConfirmDelete onConfirm={this.confirmDelete} onCancel={this.hideDropdown} />
        </div>

        <div style={{ display: state.confirm_spam ? 'block' : 'none' }}>
          <ConfirmSpam onConfirm={this.confirmSpam} onCancel={(event) => this.setState({ confirm_spam: false })} />
        </div>

        <div style={{ display: isShowMenu ? 'block' : 'none' }}>
          {state.showMenu && (
            <Menu
              is_tag={true}
              is_admin={props.is_admin}
              is_admin_post={props.is_admin_post}
              is_owner={props.is_owner}
              is_reply={props.is_reply}
              is_hidden={props.is_hidden}
              is_spam={props.is_spam}
              onClick={this.onClickMenu}
              tagArrList={props.tagArrList}
              changePostsList={props.changePostsList}
            />
          )}
        </div>
      </BtnMoreWithDropdown>
    );
  }
}

CommentOperator.propTypes = {
  /* 是否管理员 */
  is_admin: PropTypes.bool,
  /* 是否评论作者 */
  is_owner: PropTypes.bool,
  /* 是否回复 */
  is_reply: PropTypes.bool,
  /* 是否隐藏的评论 */
  is_hidden: PropTypes.bool,
  /* 是否加黑评论 */
  is_spam: PropTypes.bool,
  hasParentEle: PropTypes.bool,
  is_admin_post: PropTypes.bool,
  tagArrList: PropTypes.array,
  /* 点击菜单 */
  onClickMenu: PropTypes.func,
  /* 点击删除 */
  onClickDelete: PropTypes.func,
  changePostsList: PropTypes.func,
  onCheckLogin: PropTypes.func,
};
