/* eslint-disable camelcase, react/prop-types, react/prop-types */
import React, { Component } from 'react';
import { BtnsStyle, BtnLike, BtnReward, BtnMoreWithDropdown, BtnComment, BtnDetails } from 'components/btn-icon';
import { Menu, ConfirmDelete } from 'components/comment-item';
import { href_post } from 'components/href-helper';
import { clipboardText } from '@tencent/tc-clipboard';
import { BtnTapd } from 'components/btn-icon/btn-tapd';

class PostOperations extends Component {
  constructor(props) {
    super(props);
    this.postUrl = location.origin + href_post(this.props.id);
    this.state = {
      confirm_delete: false,
    };

    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);

    this.dropdownRef = React.createRef();
  }

  confirmDelete() {
    this.props.onClickDelete();
    this.setState({ confirm_delete: true });
  }

  cancelDelete() {
    this.setState({ confirm_delete: false });
    this.dropdownRef.current.close();
  }


  onClickMenu({ opt, val }) {
    if (opt === 'delete') {
      this.setState({ confirm_delete: true });
      return;
    }
    this.setState({ showMenu: false });
    this.props.onClickMenu && this.props.onClickMenu({ opt, val });
  }

  isOwner() {
    const { props } = this;

    if (!props.user) {
      return false;
    }

    return Boolean(Number(props.user.user_id) === Number(props.user_id));
  }

  onBtnMoreClick() {
    if (this.props.onCheckLogin()) {
      this.setState({ showMenu: true });
    }
  }

  render() {
    const { props, state } = this;
    const isOwner = this.isOwner();
    const canReward = (props.productData.tip_status === 'on' && props.rewardInfo[props.id] && props.rewardInfo[props.id].can) || false;
    let replyNum = props.reply_count || props.reply_num;
    replyNum = replyNum > 0 ? replyNum : '评论';
    return (
      <div className="post_operations">
        <BtnsStyle>
          <div className="hover_show">
            <BtnMoreWithDropdown onClick={() => this.onBtnMoreClick()} ref={this.dropdownRef}>

              <div style={{ display: state.confirm_delete ? 'block' : 'none' }}>
                <ConfirmDelete
                  onConfirm={this.confirmDelete}
                  onCancel={this.cancelDelete}
                />
              </div>

              <div style={{ display: !state.confirm_delete ? 'block' : 'none' }}>
                {
                  state.showMenu
                  && <Menu
                    id={props.id}
                    productId={props.productData.id}
                    is_reply={props.is_reply}
                    is_top={props.is_top}
                    is_locked={props.is_locked}
                    is_hidden={props.is_hidden}
                    is_spam={props.is_spam}
                    is_admin={props.user.is_admin}
                    is_good={props.is_good}
                    is_owner={isOwner}
                    is_admin_post={props.is_admin}
                    tagArrList={props.tagArrList}
                    changePostsList={props.changePostsList}
                    onClick={this.onClickMenu.bind(this)}
                    handleCopyLink={() => clipboardText(this.postUrl, '复制链接成功。')}
                  />
                }
              </div>
            </BtnMoreWithDropdown>
            <div style={{ display: canReward ? 'inline-block' : 'none' }}>
              <BtnReward canReward={canReward} onClick={props.onRewardCLick} />
            </div>
            <BtnDetails href={this.postUrl} />
          </div>

          {props.tapdEntry && <BtnTapd onClick={props.onClickTapd} />}

          {!props.isWantPost && <BtnLike
            like_count={props.like_count}
            isLiked={props.is_liked}
            onClick={props.onClickLike}
          />}
          <BtnComment onClick={props.onCommentBtn} is_disabled={props.is_locked} replyNum={replyNum} />
        </BtnsStyle>
      </div>
    );
  }
}

PostOperations.defaultProps = {
  confirm_delete: false,
  is_top: false,
  is_locked: false,
  is_hidden: false,
  is_spam: false,
  is_admin: false,
  isOwner: false,
  is_reply: false,
  tapdEntry: false,
  isWantPost: false,
  onClickMenu: () => console.log('onClickMenu 函数未绑定'),
  onClickDelete: () => console.log('onClickDelete 没绑定'),
  onCheckLogin: () => false,
  onClickTapd: () => console.log('onClickTapd 未绑定'),
};


export { PostOperations };

