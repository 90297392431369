import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TcIconetailsLine, TcIconManage } from 'components/IconSystem';

export const BtnDetails = (props) => (
  <a className="btn btn-details" target="_blank" href={props.href} rel="noreferrer">
    <TcIconetailsLine />
    <b className="btn-text">详情</b>
  </a>
);

BtnDetails.defaultProps = {
  href: 'javascript:;',
};

BtnDetails.propTypes = {
  href: PropTypes.string,
};
