import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import RichText from 'components/rich-text';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import 'components/emoji';

class DescPara extends Component {
  constructor(props) {
    super(props);
    this.myref = React.createRef();
    this.state = {
      // 是否限制文本字数
      limit: true,
      // 按钮状态显示
      btnState: false,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.lineLimit(this.myref.current);
    }, 0);
  }


  lineLimit(ele) {
    // TODO 小程序展开全文逻辑判断
    if (!ele) {
      return;
    }
    const clientH = ele.clientHeight;
    const scrollH = ele.scrollHeight;
    return scrollH > clientH
      ? (this.setState({ limit: true, btnState: true }))
      : (this.setState({ limit: false, btnState: false }));
  }

  onToggle(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState(preState => ({ limit: !preState.limit }));
  }

  render() {
    const { props, state } = this;
    return (
      <Tag.div className="desc" aria-hidden={true}>
        <RichText
          className={classNames('desc_p', { line_omit: state.limit })}
          ref={this.myref}
          content={dangerouslySetInnerHTML(props.desc, '', 'br', props.renderLink).__html}
        />
        {
          props.showLimitBtn && (state.btnState) && (
            <Tag.span
              className="desc_btn"
              // 这里让读屏器忽略这个按钮， 因为读屏是可以读完内容全部的
              aria-hidden={true}
              onClick={this.onToggle}
            >
              {(state.limit) ? '全文' : '收起'}
            </Tag.span>
          )
        }

      </Tag.div>
    );
  }
}

DescPara.defaultProps = {
  desc: '',
  showLimitBtn: true,
};
DescPara.propTypes = {
  desc: PropTypes.string,
  showLimitBtn: PropTypes.bool,
  renderLink: PropTypes.bool,
};
export { DescPara };
